import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@babel/polyfill'
import VConsole from 'vconsole'
import repaymentLoading from '@/components/repaymentLoading.vue'
import repaymentHeader from '@/components/repaymentHeader.vue'
import repaymentAgreemen from '@/components/privacyAgreementText.vue'
import '@/utils/vant'
import '@/utils/generalRecords'
import 'amfe-flexible'
import javaTransfer from '@/utils/javaTransfer'
import VueClipboard from 'vue-clipboard2'
import './filters'

Vue.use(javaTransfer)
Vue.use(VueClipboard)
// 通用打点
function generalRecords (pageName, action, extInfo) {
  this.$generalRecords({
    pageName,
    action,
    extInfo
  })
}
Vue.prototype.generalRecords = generalRecords

// 调式工具 开发环境才会开启
const isDev = process.env.NODE_ENV === 'development'
isDev && new VConsole()

Vue.component('repaymentHeader', repaymentHeader)
Vue.component('repaymentLoading', repaymentLoading)
Vue.component('repaymentAgreemen', repaymentAgreemen)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
